import { useState, useEffect } from "react";
import { fetchUsersByName } from "../../apiServices";


const UserSearchingBar = ({ form, setForm, userType, formUserKey }) => {
    const [userQuery, setUserQuery] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const [usersResult, setUsersResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {

        //load users when form changes, for handling when user selects an chat for editing
        const fullName = form[formUserKey];
        if (userQuery === '' && fullName) {
            setUserQuery(fullName);
            handleUserClick(form[formUserKey]);
        }

    }, [form]);

    useEffect(() => {
        setIsSearching(true);
        const fetchData = async () => {
            const userQueryResult = await fetchUsersByName({ userQueryName: userQuery, userTypeName: userType });

            //searching will start when the second letter's been entered
            if (isSearching) {
                console.log(userQueryResult)
                setUsersResult(userQueryResult);
            }

            //when there are no inputs in the inputbar set avatar to blank
            if (userQuery.length === 0) {
                setUserAvatar('');
            }
        };

        //type and wait 0.3s before searchin starts
        const debounceId = setTimeout(fetchData, 300);
        return () => {
            clearTimeout(debounceId);
        };
    }, [userQuery]);

    //handles when user search and pick another user
    const handleUserClick = (user) => {
        setUserQuery(user.givenName + " " + user.familyName);
        setForm({ ...form, [formUserKey]: user._id });
        setUserAvatar(user.avatar);
        setIsSearching(false);
        setUsersResult([]);
    }
    return (
        <div className="user-container">
            {userAvatar ? (
                <img style={{ margin: "5px", width: "30px", height: "30px" }} src={userAvatar} alt="头像不见了" />
            ) : (<div />)}
            <input
                style={{ maxWidth: "30vw", minHeight: "5vh" }}
                type="text"
                value={userQuery}
                onChange={(e) => { setUserQuery(e.target.value); console.log("haschanged") }}
                placeholder="搜索用户..."
            />
            {(usersResult.length !== 0 && isSearching) ? (< div >
                <ul className="users-dropdown-menu">
                    {usersResult.map(user => (
                        <li key={user._id} className="user-container users-dropdown-item" onClick={() => (handleUserClick(user))} >
                            <img src={user.avatar} alt="头像不见了" /> &nbsp;{user.givenName}&nbsp;{user.familyName}&nbsp;
                        </li>
                    ))}
                </ul>
            </div>
            ) : (<div />)}

        </div>);
}

export default UserSearchingBar;