require('./button.scss');


//scssChoice option: motion-button,
const Button = ({ id, onClick, children, scssChoice, type, style }) => {
    return (
        <button style={style} type={type} id={id} onClick={onClick} className={scssChoice}>
            {children}
        </button>
    );
};

export default Button;