import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState, useEffect } from "react";
import { getAllChats } from "../../apiServices";
import { Oval } from 'react-loading-icons';
import { useNavigate } from "react-router-dom";
import "./chatsHistory.scss";
import StarsRating from "react-star-rate";

const ChatsHistory = () => {
    const [isLoading, setIsLoding] = useState(true);
    const [expiredChats, setExpiredChats] = useState([]);
    const [noChatsHistory, setNoChatsHistory] = useState(false);
    const navigate = useNavigate();

    const handleChatClick = (id) => {
        navigate(`/ExpiredChat/${id}`);
    };

    const fetchChatsHistory = async () => {
        try {
            // Get all expired chats, true indicates  expired
            const allChats = (await getAllChats(true));
            setExpiredChats(allChats);
            if (allChats.length === 0) {
                setNoChatsHistory(true);
            }
        } catch (error) {
            console.error("Failed to fetch chats:", error);
        } finally {
            setIsLoding(false);
        }
    }

    useEffect(() => {
        //get all expired chats.
        fetchChatsHistory()
    }, []);

    return (
        <div>
            <div className="body-container share-background">
                <Header />
                <img id="water-color" src="/watercolor.png" alt=" " />
                <div style={{ justifyContent: "center", flexDirection: "column", alignItems: "center" }} className="canvas-row">
                    <h1 style={{ fontSize: "2.8rem", fontWeight: "400", whiteSpace: "nowrap", letterSpacing: "1vw" }}>历史活动收藏夹</h1>

                    {noChatsHistory ? (<h1 style={{ color: "black" }}>更多活动敬请期待！</h1>
                    ) : (isLoading ? (<div style={{ alignSelf: "center" }}><Oval style={{ position: "relative", top: "0vh" }} className="loading-animation" stroke="#1f239ca9" speed={.75} ></Oval></div>
                    ) : (<div >
                        <div className="history-container">
                            {expiredChats.map(oneChat => (
                                <ChatCard oneChat={oneChat} key={oneChat._id} className="single-chat" cardClick={() => handleChatClick(oneChat._id)} />))}
                        </div>
                    </div>))}
                </div>

            </div>
            <Footer />
        </div>
    );
}

const ChatCard = ({ oneChat, cardClick }) => {
    const starStyles = {
        style: { height: '25px', fontSize: '20px', alignSelf: 'flex-end' },

        full: {
            star: { fontSize: '20px' },
        },
        half: {
            star: { fontSize: '20px' },
        },
        zero: {
            star: { fontSize: '20px', color: 'lightgrey' },

        },
    };
    return (
        <div onClick={cardClick} className="single-chat-container">
            <h4 style={{ margin: "0", fontSize: "1.5rem", fontWeight: "900", text: "center" }}> {oneChat.title} </h4>
            <br />
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5vh" }}>
                <div>活动时间:{oneChat.time}</div>
                <div>活动地址:{oneChat.address}</div>
                <div>报名人数:&nbsp;{oneChat.participants.length}</div>
            </div>
            <div >
                <div className="user-container stay-right">
                    主持人:&nbsp;{oneChat.host.givenName}&nbsp;{oneChat.host.familyName}&nbsp;&nbsp;
                    <img src={oneChat.host.avatar} alt="头像不见了" /> <br />
                </div>
                <div className="user-container stay-right">
                    嘉宾:&nbsp;{oneChat.guest.givenName}&nbsp;{oneChat.guest.familyName}&nbsp;&nbsp;
                    <img src={oneChat.guest.avatar} alt="头像不见了" /> <br />
                </div>
                <div className="user-container stay-right" >
                    <StarsRating
                        value={oneChat.totalRate}
                        disabled={true}
                        style={starStyles}
                    />
                </div>
            </div>

        </div>
    );
}
export default ChatsHistory;