import { useParams } from "react-router-dom";
import { fetchUserDetails, getOneChat } from "../../apiServices";
import { useEffect, useState } from "react";
import "./expiredChat.css";
import { Oval } from "react-loading-icons";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Button from "../../Components/Button";
import { getFeedbackByQuery } from "../../apiServices";
import StarsRating from "react-star-rate";
import FeedbackForm from "./feedbackForm";
import { motion } from 'framer-motion';

const ChatDetail = () => {
    const [chatDetail, setChatDetail] = useState({});
    const { chatId } = useParams();
    const [feedbacks, setFeedbacks] = useState([]);
    const [allfeedbacks, setAllfeedbacks] = useState([]);
    const [positiveFeedbacks, setPositiveFeedbacks] = useState([]);
    const [neutralFeedbacks, setNeutralFeedbacks] = useState([]);
    const [negativeFeedbacks, setNegativeFeedbacks] = useState([]);
    const [feedbackClicked, setFeedbackClicked] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const fetchChatDetail = async () => {
        try {
            const oneChat = await getOneChat(chatId);
            setChatDetail(oneChat);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    //get all feedbacks for this chat and classify them
    const getFeedbacksForChat = async () => {
        try {
            const feedbacksDetail = await getFeedbackByQuery({ chat: chatId });
            setFeedbacks(feedbacksDetail);
            setAllfeedbacks(feedbacksDetail);

            //sort all ratings
            const { positive, neutral, negative } = feedbacksDetail.reduce((acc, feedback) => {
                if (feedback.rate > 4) {
                    acc.positive.push(feedback);
                } else if (feedback.rate > 2.5) {
                    acc.neutral.push(feedback);
                } else {
                    acc.negative.push(feedback);
                }
                if (feedback.user._id === sessionStorage.getItem("userId")) {
                    setHasSubmitted(true);
                }
                return acc;
            }, { positive: [], neutral: [], negative: [] });
            setPositiveFeedbacks(positive);
            setNeutralFeedbacks(neutral);
            setNegativeFeedbacks(negative);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handlefeedbackClick = async () => {
        //check if user is legit to give a feedback; order matters; 
        const userId = window.sessionStorage.getItem("userId");
        if (!userId) {
            alert("请先登陆！");
            return;
        }
        if (hasSubmitted) {
            alert("你已经评价过了");
            return;
        }

        const userData = await fetchUserDetails(userId);
        if (userData && !userData.attendedChats.includes(chatId)) {
            alert("您没参加呢这个活动呢？")
        } else {
            setFeedbackClicked(true);
        }
    }

    useEffect(() => {

        fetchChatDetail();
        getFeedbacksForChat();

    }, [chatId]);

    const chatStarStyles = {

        style: { position: "absolute", bottom: "2vh", alignSelf: "flex-end" },

        full: {
            star: { fontSize: '30px' },
        },
        half: {
            star: { fontSize: '30px' },
        },
        zero: {
            star: { fontSize: '20px', color: 'lightgrey' },
        },
    };

    const feedbackStarStyles = {

        style: { position: "relative", alignSelf: "flex-end" },

        full: {
            star: { fontSize: '20px' },
        },
        half: {
            star: { fontSize: '20px' },
        },
        zero: {
            star: { fontSize: '20px', color: 'lightgrey' },
        },
    };
    return (
        <div>
            <Header />
            <div className="body-container share-background">

                <div id="expired-chat" className="canvas-row ">
                    {!chatDetail.host ? (<div className="expired-chat-container" style={{ background: "none", border: "none", boxShadow: "none" }}>
                        <Oval className="loading-animation" stroke="#1f239ca9" speed={.75} />
                    </div>
                    ) : (
                        feedbackClicked ? (<FeedbackForm chatId={chatId} userId={sessionStorage.getItem("userId")} />
                        ) : (
                            <motion.div
                                initial={{ opacity: 0, y: -40 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 2, type: "spring", stiffness: 200 }}
                                className="expired-chat-container">
                                <h2 style={{ alignContent: "center" }}> {chatDetail.title} </h2>
                                <div className="text-container">
                                    <h3>活动介绍:</h3>
                                    <p className="detail-style ">{chatDetail.introduction}</p>
                                    <span>活动时间:&nbsp;{chatDetail.time}</span>
                                    <span>活动地址:&nbsp;{chatDetail.address}</span>
                                </div>
                                <div className="user-container">
                                    <span style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
                                        主持人:&nbsp;{chatDetail.host.givenName}&nbsp;{chatDetail.host.familyName}&nbsp;&nbsp;
                                        <img style={{ borderRadius: "20px", margin: "5px", width: "30px", height: "30px" }} src={chatDetail.host.avatar} alt="头像不见了" />
                                    </span>
                                    <span className="text-ellipsis">
                                        &nbsp; 嘉宾:&nbsp;{chatDetail.guest}
                                    </span>
                                </div>
                                <span>报名人数:&nbsp;{chatDetail.participants.length}</span>
                                <Button id="leave-comment-button" onClick={() => { handlefeedbackClick() }} scssChoice="fill-button">
                                    留个评论吧!
                                </Button>
                                <StarsRating
                                    value={chatDetail.totalRate}
                                    disabled={true}
                                    style={chatStarStyles}
                                />
                            </motion.div >))}
                    <div className="comment-categorization-container">
                        <button onClick={() => { setFeedbacks(allfeedbacks) }}>全部评论({allfeedbacks.length})</button>
                        <button onClick={() => { setFeedbacks(positiveFeedbacks) }}>好评({positiveFeedbacks.length})</button>
                        <button onClick={() => { setFeedbacks(neutralFeedbacks) }}>中评({neutralFeedbacks.length})</button>
                        <button onClick={() => { setFeedbacks(negativeFeedbacks) }}>差评({negativeFeedbacks.length})</button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        {!feedbacks ? (<Oval className="loding-animation" stroke="#1f239ca9" speed={.75} />
                        ) : (
                            feedbacks.length === 0 ? (<h2 className="feedbacks-display-container" >暂时还没有评论呢～</h2>
                            ) : (<motion.div
                                initial={{ opacity: 0, x: 40 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.5, duration: 0.5, ease: "easeInOut" }}
                                className="feedbacks-display-container"
                            >
                                {feedbacks.map(feedback => (
                                    <div key={feedback._id} className="feedback feedback-detail-container" >
                                        <h3>{feedback.title}</h3>
                                        <div>
                                            <div> <img src={feedback.user.avatar} alt="头像不见了" /> &nbsp;{feedback.user.givenName}&nbsp;{feedback.user.familyName}&nbsp;</div>
                                            <span style={{ color: 'grey' }}>{feedback.createdAt}</span>
                                        </div>
                                        <span>{feedback.comment}</span>
                                        <StarsRating
                                            value={feedback.rate}
                                            disabled={true}
                                            style={feedbackStarStyles}
                                        />
                                    </div>))
                                }
                            </motion.div>
                            ))}
                    </div>
                    <img className="flower-shadow" src={"/flower-shadow.png"} alt="图片不见了" />
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default ChatDetail;