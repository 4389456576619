import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatDetail from './Pages/ChatDetail';
import ChatsHistory from './Pages/ChatsHistory';
import CreateChat from './Pages/CreateChat';
import ExpiredChat from './Pages/ExpiredChat';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import TermsOfUse from './Pages/TermsOfUse';


const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ChatDetail/:chatId" element={<ChatDetail />} />
            <Route path="/ChatHistory" element={<ChatsHistory />} />
            <Route path="/CreateChat" element={<CreateChat />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            <Route path="/ExpiredChat/:chatId" element={<ExpiredChat />} />
        </Routes>
    </Router>
);


export default App