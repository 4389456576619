import("./inspirationCard.scss");



const InspirationCard = ({ title, children }) => {

    return (
        <div className="inspiration-card">
            <div>
                <img src="/inspiration.png" alt="图片不见了!" />
                <h2>{title}</h2>
            </div>
            <span>{children}</span>
        </div>
    )
};

export default InspirationCard;