import { useParams } from "react-router-dom";
import { getOneChat } from "../../apiServices";
import { useEffect, useState } from "react";
import "./chatDetail.css";
import { Oval } from "react-loading-icons";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Button from "../../Components/Button";
import axios from "axios";
import { fetchUserDetails } from "../../apiServices";
import UserSearchingBar from "../../Components/UserSearchingBar";
import { motion } from 'framer-motion';



const ChatDetail = () => {
    const [chatDetail, setChatDetail] = useState({});
    const { chatId } = useParams();
    const [buttonName, setButtonName] = useState("点击报名");
    const [userDetail, setUserDetail] = useState("");
    const [registration, setRegistration] = useState(false);
    const [form, setForm] = useState({
        userId: ''
    });

    const userId = window.sessionStorage.getItem("userId")

    //when a standard user clicked sign-up button
    const userClicked = async () => {
        if (userId) {
            const userDetail = await fetchUserDetails(userId);
            if (!userDetail.bookedChats.includes(chatId)) {
                if (userDetail.userType !== "User") {
                    return "普通用户才需要报名！"
                }
                const updateUserData = { _id: userId, bookedChats: [...userDetail.bookedChats, chatId] }
                axios.patch(process.env.REACT_APP_BACKEND_DOMAIN + '/user/update-one-user', updateUserData, { withCredentials: true })
                    .then(response => {
                        console.log("报名成功！ ");
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

                const { time, ...chatDetailWithoutTime } = chatDetail;
                const updateChatData = { ...chatDetailWithoutTime, participants: [...chatDetail.participants, userId] }
                console.log("updateChatData ", updateChatData);
                axios.patch(process.env.REACT_APP_BACKEND_DOMAIN + '/chat/update-one-chat', updateChatData, { withCredentials: true })
                    .then(response => {
                        console.log("This chat has recorded this " + userDetail.givenName + " user attended ");
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                return "报名成功！";
            } else {
                return "你已经报名了!";
            }
        } else {
            return "请先登陆";
        }
    }

    //when a admin needs to register the attended users
    const userAttended = async (attendedUserId) => {

        try {
            const attendedUser = await fetchUserDetails(attendedUserId);
            if (!attendedUser.bookedChats.includes(chatId)) {
                return "用户还没有报名，请先报名!"
            } else if (attendedUser.attendedChats.includes(chatId)) {
                return "用户已经签到了！";
            } else {
                const updateUserData = { _id: attendedUserId, attendedChats: [...attendedUser.attendedChats, chatId] }
                return axios.patch(process.env.REACT_APP_BACKEND_DOMAIN + '/user/update-one-user', updateUserData, { withCredentials: true })
                    .then(response => {
                        console.log("This user has attended this chat now " + response.data);
                        return "用户签到成功！";
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    //if user is anAdmin then showing up the registration window for registering attendees
    const handleRegisterClick = async (attendedUserId) => {
        console.log("attendedUserId", attendedUserId);
        let result = '';
        //current user must be admin, then check if the usr being searched 
        if (userDetail.userType === "Admin") {
            if (attendedUserId) {
                result = await userAttended(attendedUserId);
                window.location.reload();
            } else {
                result = "没找到用户勒？";
            }
            alert(result);

        } else {
            result = await userClicked();
            alert(result);
        }
    }

    //get user's and chat's data
    const getUserDetails = async () => {
        try {
            const userData = await fetchUserDetails(userId);
            setUserDetail(userData);
            if (userData.userType === "Admin") {
                setButtonName("登记入口");
                setRegistration(true);
            }
        } catch (error) {
            console.log("error", error);
        }

    }
    const fetchChatDetail = async () => {
        try {
            const oneChat = await getOneChat(chatId);
            setChatDetail(oneChat);
        } catch (error) {
            console.log("error", error);
        }

    };

    useEffect(() => {

        getUserDetails();
        fetchChatDetail();

    }, []);

    return (
        <div>
            <Header />
            <div className="body-container">
                <div id="chat-datail" className="canvas-row">
                    {!chatDetail.host ? (<div className="future-chat-detail-container"><Oval className=" loading-animation" stroke="#1f239ca9" speed={.75} /></div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, x: -40 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 2, ease: "easeOut" }}
                            className="future-chat-detail-container">
                            <h2 style={{ margin: "0", alignContent: "center" }}> {chatDetail.title} </h2>
                            <div className="text-container">
                                <h3>活动介绍</h3>
                                <p className="detail-style ">{chatDetail.introduction}</p>
                                <span>活动时间:{chatDetail.time}</span>
                                <br />
                                <span>活动地址:{chatDetail.address}</span>
                            </div>
                            <div className="user-container">
                                <span style={{ display: "flex", width: "50%", alignItems: "center" }}>
                                    主持人:&nbsp;{chatDetail.host.givenName}&nbsp;{chatDetail.host.familyName}&nbsp;&nbsp;
                                    <img style={{ borderRadius: "20px", margin: "5px", width: "30px", height: "30px" }} src={chatDetail.host.avatar} alt="头像不见了" />
                                </span>
                                <span className="text-ellipsis">
                                    嘉宾:&nbsp;{chatDetail.guest}
                                </span>
                            </div>
                            <span> 已报名人数:&nbsp;{chatDetail.participants.length}</span>
                            <Button id="detail-register-button" onClick={() => { handleRegisterClick(form.userId) }} scssChoice="fill-button">
                                {buttonName}
                            </Button>
                            {registration ? (<div style={{ position: "relative", top: "-5vh" }} >
                                <UserSearchingBar
                                    form={form}
                                    setForm={setForm}
                                    userType={"User"}
                                    formUserKey={"userId"}
                                />
                            </div>) : (<div />)}
                        </motion.div>)}
                    <img style={{ height: "100%" }} src={"/carton-houses.png"} alt="头像不见了" />
                    <motion.img initial={{ opacity: 0, x: 40 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        id="poster" style={{ right: "0" }} src={"/woman-color.png"} alt="头像不见了" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ChatDetail;