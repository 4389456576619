import axios from "axios";
// only frequently being used axios functions will be stored in here

//change time info to readable
function formatIsoDateToReadable(isoDate) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options).replace(',', '').replace(' AM', 'am').replace(' PM', 'pm');
}

//change time info to readable but shot
function formatIsoDateToReadableShoter(isoDate) {
    const date = new Date(isoDate);
    const options = { weekday: 'short', year: '2-digit', month: 'short', day: 'numeric' };
    return date.toLocaleString('en-US', options).replace(',', '');
}

//fetch one user‘s data
export const fetchUserDetails = async (id) => {
    return axios.get(process.env.REACT_APP_BACKEND_DOMAIN + '/user/find-one-user', { params: { userId: id } })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return null;
        });
}

//fetch users by query
export const findUserByQuery = async ({ anyQuery }) => {

    return axios.get(process.env.REACT_APP_BACKEND_DOMAIN + '/user/find-all-user-query', { params: { anyQuery: anyQuery } })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
            return null;
        });
}

export const fetchUsersByName = async ({ userQueryName = "", userTypeName = "User" }) => {
    try {
        // debouncing like query.length > 2
        if (userQueryName.length > 2) {
            const resultArray = await findUserByQuery({ anyQuery: { nameIncludedIn: userQueryName, userType: userTypeName } });
            return resultArray;
        }
        //clear host dropdown menu when input for host area is cleared
        if (userQueryName.length === 0) {
            return [];
        }
    } catch (error) {
        console.log(error);
        return [];
    }
    return [];
};

//get all future chats or expired chat
export const getAllChats = async (expiredBoolean) => {

    //get all chats based on options
    return axios.get(process.env.REACT_APP_BACKEND_DOMAIN + "/chat/get-all-chats", { params: { expired: expiredBoolean } })
        .then(async (response) => {

            let allChats = [];
            //sorted based on time
            if (expiredBoolean) {
                allChats = response.data.sort((b, a) => new Date(a.time) - new Date(b.time));
            } else {

                allChats = response.data.sort((a, b) => new Date(a.time) - new Date(b.time));
            }

            //query about the details of host
            return await Promise.all(allChats.map(
                async (chat) => {
                    const time = formatIsoDateToReadable(chat.time);
                    const host = await fetchUserDetails(chat.host);

                    return {
                        ...chat,
                        time,
                        host
                    };
                }));

        }
        ).catch(
            error => {
                console.error('Error:', error);
                return Promise.reject('Failed to fetch chats');
            }
        )
}

export const getOneChat = async (id) => {

    //get all chats based on options
    return axios.get(process.env.REACT_APP_BACKEND_DOMAIN + "/chat/get-one-chat", { params: { _id: id } })
        .then(async (response) => {
            const chatDetail = response.data;
            const time = formatIsoDateToReadable(chatDetail.time);
            const host = await fetchUserDetails(chatDetail.host._id);
            return {
                ...chatDetail,
                time,
                host
            }
        }
        ).catch(
            error => {
                console.error('Error:', error);
                return Promise.reject('Failed to fetch chats');
            }
        )
}

export const getFeedbackByQuery = async (anyQuery) => {
    //get all good feedbacks
    return axios.get(process.env.REACT_APP_BACKEND_DOMAIN + "/feedback/get-all-feedback-query", { params: anyQuery })
        .then(async (response) => {
            // console.log(response.data);
            const feedbacksDetail = await Promise.all(response.data.map(
                async (feedback) => {
                    const user = await fetchUserDetails(feedback.user);
                    const createdAt = formatIsoDateToReadableShoter(feedback.createdAt);

                    return {
                        ...feedback,
                        user,
                        createdAt,
                    };
                }));
            return feedbacksDetail;
        }).catch(
            error => {
                console.error('Error:', error);
            }
        )


}