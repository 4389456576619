import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Button from "../../Components/Button";
import InspirationCard from "./InspirationCard";
import Feedbacks from "./Feedbacks";
import FutureChats from "./FutureChats";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import "./home.scss";

const Home = () => {
    const targetRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    //scroll to the part of future activies
    const scrollToTarget = () => {
        navigate(location.pathname, { state: { shouldScroll: true } });
    };

    const handleHistoryClick = () => {
        navigate("/ChatHistory");
    }
    useEffect(() => {

        //scroll to position when shouldScroll being set to true
        if (location.state?.shouldScroll) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
            navigate(location.pathname, { state: {}, replace: true });
        }

    }, [location, navigate]);

    const fadeInAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { duration: 1.5, ease: "easeOut" }
    };
    return (
        <div>
            <Header />
            <div className="body-container">
                <div id="first-part" className="canvas-row">
                    <motion.div initial={{ opacity: 0, x: -40 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 2, ease: "easeOut" }}
                        className="slogan">
                        <span id="slogan-one">
                            “这是一处安全的心理港湾，
                        </span>
                        <motion.span
                            initial={{ opacity: 0, x: 80 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 2, ease: "easeOut" }}
                            id="slogan-two">
                            让我们在这里相遇，
                        </motion.span>
                        <span id="slogan-three">
                            一起寻找生命的灯塔”
                        </span>
                        <motion.div style={{ display: "flex" }}{...fadeInAnimation}>
                            <Button id="register-button" onClick={() => scrollToTarget("future-event")} scssChoice="fill-button">
                                点击报名!
                            </Button>
                        </motion.div>

                    </motion.div>

                    <motion.img {...fadeInAnimation}
                        id="image-man" src="/home_image_man.png" alt="图片不见了!" />
                </div>
                <div id="second-part" className="canvas-column">

                    <h3>
                        活动亮点
                    </h3>
                    <div className="cards-container">
                        <InspirationCard title="解决生活烦恼">我们深入心理学的精粹，解析参与者生活中的烦恼纠结。通过传授实用技巧，来确保您的每一步都是走向宁静与满足的坚实脚步！</InspirationCard>
                        <InspirationCard title="丰富社交活动">我们致力于打造充满乐趣的社交活动，帮助大家结交新朋友，开启心灵的对话，和与您有相同烦恼的朋友一起面对并克服生活中的挑战！</InspirationCard>
                        <InspirationCard title="学习心知识">我们鼓励深入探索易于理解的心理学知识，帮助您更好地认识自己和他人。从心理学的角度理解这个社会，帮助您掌握自身成长的节奏。</InspirationCard>
                    </div>
                    <img id="features-background" src="/features-background.png" alt="图片不见了!" />
                </div>
                <div id="third-part" className="canvas-column">
                    {/* <img id="eye-img" src="/eye.png" alt="图片不见了!" /> */}
                    <div style={{ position: "relative", top: "-10vh" }} ref={targetRef} />
                    <h3>
                        未来活动！
                    </h3>
                    <motion.div initial={{ opacity: 0, x: -40 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1, delay: 0.5 }}><FutureChats /></motion.div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        < Button id="chats-history" onClick={() => handleHistoryClick()} scssChoice="pulse-button">
                            历史活动&nbsp;&gt;
                        </Button>
                    </div>
                    <span className="good-feedbacks">好评打卡!!</span>
                    <Feedbacks />
                    <img id="samantha" src="/samantha.jpg" alt="图片不见了!" />
                </div>

            </div>
            <Footer />
        </div >
    )
};

export default Home;