import { Link } from 'react-router-dom';

import('./footer.scss');


const Footer = () => {



    return (


        <div className='footer-container'>
            <a href="https://www.psyckitchen.com/" target="_blank" rel="noopener noreferrer">糖醋鱼官网</a>
            <Link to="/AboutUs" >关于聊聊心理吧</Link>
            <a style={{ textDecoration: 'none', cursor: "default" }} href="/" onClick={(e) => e.preventDefault()}>
                <img style={{ width: '60px' }} src='/wechatOfficial.webp' alt='图片不见了！' />
                糖醋鱼微信公众号
            </a>
            <a href="mailto:psyckitchenservice@gmail.com?subject=Hi, please fill out your full name and your enquiry. Thank you! : )" >联系我们</a>
            <Link to="/TermsOfUse" >使用条款</Link>
        </div>
    );
}


export default Footer;