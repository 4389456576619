import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Oval } from 'react-loading-icons'
import { getAllChats } from "../../../apiServices"
import("./futureChats.scss");

const FutureChats = () => {
    const navigate = useNavigate();
    const [plannedChats, setPlannedChats] = useState([]);
    const [isLoding, setIsLoding] = useState(true);
    const [noEvent, setNoEvent] = useState(false);

    const handleChatClick = (id) => {
        navigate(`/ChatDetail/${id}`);
    };

    //fetch all future chats and only list first three
    const fetchComingChats = async () => {
        try {
            // Get all future chats, false indicates not expired
            const allChats = (await getAllChats(false)).slice(0, 3);
            setPlannedChats(allChats);

            //if no events then show no events html
            if (allChats.length === 0) {
                setNoEvent(true);
            }
        } catch (error) {
            console.error("Failed to fetch chats:", error);
        } finally {
            setIsLoding(false);
        }
    }

    useEffect(() => {
        fetchComingChats();
    }, []);

    return (
        <div className="future-chats-container">
            {noEvent ? (<h1 style={{ color: "black" }}>更多活动敬请期待！</h1>
            ) : (isLoding ? (<Oval className="loding-animation" stroke="#1f239ca9" speed={.75} />
            ) : (
                plannedChats.map(oneChat => (
                    <div key={oneChat._id} className="single-chat" onClick={() => handleChatClick(oneChat._id)}>
                        <h2> {oneChat.title} </h2>
                        <div className="single-chat-chunk">
                            <span>活动时间:{oneChat.time}</span>
                            <span>活动地址:{oneChat.address}</span>
                        </div>
                        <div className="single-chat-chunk">
                            <span>
                                主持人:&nbsp;{oneChat.host.givenName}&nbsp;{oneChat.host.familyName}&nbsp;&nbsp;<img src={oneChat.host.avatar} alt="头像不见了" />
                            </span>
                            <span className="text-ellipsis">
                                嘉宾:&nbsp;{oneChat.guest}
                            </span>
                            <span> 报名人数:&nbsp;{oneChat.participants.length}</span>
                        </div>
                    </div>))
            )
            )}

        </div >
    )
};

export default FutureChats;