
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";



const AboutUs = () => {


    return (
        <div>
            <div className="body-container share-background">
                <Header />
                <h1 style={{ display: "flex", textAlign: "center", justifyContent: "space-around", margin: "10vh 1em 0 1em", letterSpacing: "4px" }}>关于“聊聊心理吧”</h1>
                <div style={{ alignSelf: "center", maxWidth: "80vw", fontSize: "25px", letterSpacing: "4px" }} className="text-container">

                    <p>
                        “聊聊心理吧”是<a href="https://www.psyckitchen.com/">糖醋鱼</a>长期举办的一个专注于探索人们心灵、促进精神成长和社交融合的平台，我们通过举办有意义的对话和活动，为人们提供一个<b>释放压力、解决生活烦恼</b>和<b>建立新友谊的</b>独特空间。
                    </p>
                    <p>
                        同时我们也致力于提供一个开放而温馨的环境，将解决生活烦恼，无压力倾诉，以及心理学知识和案例分享，作为主要活动特点；
                        <br />
                        <br />
                        将自由演讲和有创意的社交作为主要形式。
                        <br />
                        <br />
                        希望能让参与者能够在面对生活中的挑战时，通过心理学的理解和技巧学习，提升自我成长的韧性，同时在这个过程中结识志同道合的朋友。
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;