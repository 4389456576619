import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useEffect, useState } from "react";
import Button from "../../Components/Button";
import axios from "axios"
import "./createChat.scss";
import { getAllChats } from "../../apiServices"
import UserSearchingBar from "../../Components/UserSearchingBar"
import { Oval } from 'react-loading-icons'
import { format, parse } from 'date-fns';

const CreateChat = () => {
    const [allChats, setAllchats] = useState([]);
    const [updatingState, setUpdatingState] = useState(false);
    const [form, setForm] = useState({
        title: '',
        introduction: '',
        host: '',
        guest: '',
        time: '',
        address: '',
        surveyLink: '',
    });

    const handleKeyDown = (event) => {
        //prevent enter key from submit form
        if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
            event.preventDefault();
        }
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        // Prevents the default form submit action
        e.preventDefault();
        const readyForm = { ...form, time: `${form.time}:00.000Z` }
        console.log(readyForm);

        //If the user is updating an existing form, use the PATCH method; otherwise, use the POST method
        if (updatingState) {

            await axios.patch(process.env.REACT_APP_BACKEND_DOMAIN + '/chat/update-one-chat', readyForm, { withCredentials: true })
                .then(response => {
                    console.log(response);
                    alert("活动更新成功");
                    console.log('Form updated:', readyForm);
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert("输入有误，再检查检查～");
                });
        } else {
            await axios.post(process.env.REACT_APP_BACKEND_DOMAIN + '/chat/create-new-chat', readyForm, { withCredentials: true })
                .then(response => {
                    console.log(response);
                    alert("活动创建成功");

                    console.log('Form submitted:', readyForm);
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert("输入有误，再检查检查～");
                });
        }

        window.location.reload();
    };

    function convertDateTime(input) {
        // parse date&time "MM/dd/yyyy ha" like "04/23/2024 8pm"）
        const originalFormat = parse(input, "MM/dd/yyyy ha", new Date());

        // to ISO8601 format "yyyy-MM-dd'T'HH:mm"
        return format(originalFormat, "yyyy-MM-dd'T'HH:mm");
    }

    //load chat details when user trys to edit the chat
    const editChatClicked = (chat) => {
        const standardTime = convertDateTime(chat.time);
        setUpdatingState(true);
        setForm({
            _id: chat._id,
            title: chat.title,
            introduction: chat.introduction,
            host: chat.host,
            guest: chat.guest,
            time: standardTime,
            address: chat.address,
            surveyLink: chat.surveyLink,
        })
    }

    //fetch all future chats and only list first three
    const getAllFutureChats = async () => {
        try {
            // Get all future chats, false indicates not expired
            const chatsData = (await getAllChats(false));
            setAllchats(chatsData);

        } catch (error) {
            console.error("Failed to fetch future chats in createChat page:", error);
        }
    }

    useEffect(() => {
        getAllFutureChats();
    }, [])
    return (
        <div>
            <div className="body-container share-background">
                <Header />
                <div >
                    <div id="create-chat-page" style={{ justifyContent: "center" }} className="canvas-row">
                        <form onKeyDown={handleKeyDown} className="form-container" onSubmit={handleSubmit}>
                            <div>
                                <label className="required-mark" htmlFor="title">活动标题:&nbsp;</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    maxLength="30"
                                    placeholder="请输入活动标题！"
                                    value={form.title}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="required-mark" htmlFor="introduction">活动简介:&nbsp;</label>
                                <textarea
                                    style={{ minWidth: "50vw", minHeight: "20vh" }}
                                    type="textarea"
                                    id="introduction"
                                    name="introduction"
                                    placeholder="请输入活动简介！"
                                    maxLength="1000"
                                    value={form.introduction}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="required-mark" htmlFor="host">主持人:&nbsp;</label>
                                <UserSearchingBar
                                    form={form}
                                    setForm={setForm}
                                    userType={"Host"}
                                    formUserKey={"host"}
                                />
                            </div>
                            <div>
                                <label className="required-mark" htmlFor="guest">嘉宾:&nbsp;</label>
                                <input
                                    style={{ maxWidth: "30vw", minHeight: "5vh" }}
                                    type="text"
                                    id="guest"
                                    name="guest"
                                    placeholder="请输入嘉宾名字！"
                                    maxLength="100"
                                    value={form.guest}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="required-mark" htmlFor="time">活动日期:&nbsp;</label>
                                <input
                                    type="datetime-local"
                                    id="time"
                                    name="time"
                                    placeholder="请输入活动时间！"
                                    value={form.time}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div>
                                <label className="required-mark" htmlFor="address">活动地点:&nbsp;</label>
                                <input
                                    style={{ minWidth: "50vw", minHeight: "5vh" }}
                                    type="text"
                                    id="address"
                                    name="address"
                                    maxLength="100"
                                    placeholder="请输入活动地点！"
                                    value={form.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label style={{ fontSize: "1.5vw" }} htmlFor="email">报名调查链接:&nbsp;</label>
                                <input
                                    type="url"
                                    id="surveyLink"
                                    name="surveyLink"
                                    maxLength="100"
                                    placeholder="请输入问卷地址！"
                                    value={form.surveyLink}
                                    onChange={handleChange}
                                />
                            </div>
                            <Button style={{ backgroundColor: "#5D569D" }} scssChoice="pulse-button" type="submit">Submit</Button>
                        </form>
                        <ul className="chats-list-container">
                            <h2>已创建的活动</h2>
                            {allChats ? (
                                allChats.map((chat) => (
                                    <li key={chat._id} className="single-chat-box" onClick={() => editChatClicked(chat)}>
                                        <h3> {chat.title} </h3>
                                        <span>活动时间:{chat.time}</span>
                                        <span>活动地址:{chat.address}</span>
                                        <span> 报名人数:&nbsp;{chat.participants.length}</span>

                                    </li>
                                ))
                            ) : (
                                <Oval className="loding-animation" stroke="#1f239ca9" speed={.75} />)}
                        </ul>
                    </div >



                </div >

            </div >
            <Footer />
        </div>
    );
}


export default CreateChat;


