import Button from "../../Components/Button";
import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import('./header.scss');

const Header = () => {
    const [userData, setUserData] = useState(null);
    const [userType, setUserType] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const navigate = useNavigate();
    const legitUser = ["Admin", "Host"];
    const [isMobile, setIsMobile] = useState(false);

    //click sing up to redirect to google auth0
    const handleLoginClick = () => {
        window.location = process.env.REACT_APP_BACKEND_DOMAIN + "/user/oauth2-google";

    }

    const handleLogoutClick = () => {
        sessionStorage.removeItem('userId');
        navigate("/");
        window.location.reload();
    }
    const handleCreateChatClick = () => {
        navigate("/CreateChat/");
    }

    useEffect(() => {
        if (window.innerWidth < 600) {
            setIsMobile(true);
        }

        //store user's id
        let storedUserId = sessionStorage.getItem('userId');
        if (!storedUserId) {
            storedUserId = userId;
        }
        //get and store the user's data after login i
        if (storedUserId) {
            axios.get(process.env.REACT_APP_BACKEND_DOMAIN + '/user/find-one-user', { params: { userId: storedUserId } }, { withCredentials: true })
                .then(response => {
                    setUserData(response.data);
                    console.log(response.data);
                    sessionStorage.setItem('userId', storedUserId);
                    console.log(response.data.userType);
                    //position matters，dont move
                    if (legitUser.includes(response.data.userType)) {
                        setUserType(true);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [userId]);

    return (
        <nav className="navbar">
            <a href="/">
                <img src="/tcy_logo125.png" alt="图片不见了！" />
                <span>x 聊聊心理吧！</span>
            </a>
            {/* {isMobile && <img className="menu-icon" src="/menu_icon.png" alt="菜单" onClick={() => setIsMenuOpen(!isMenuOpen)} />} */}
            <div className="menu">
                {!isMobile && (<div className="menu">
                    <Link to={"/"} state={{ shouldScroll: true }} className="nav-item" >未来活动</Link>
                    <a className="nav-item" href="/ChatHistory">活动历史</a>
                </div>
                )}
                {userData ? (
                    <div className="user-info" >
                        <img src={userData.avatar} alt="头像嘞？" />
                        <span>{userData.givenName + " " + userData.familyName}</span>
                        {userType ? (<span style={{ color: "yellow" }}>&nbsp;({userData.userType})</span>
                        ) : (
                            <div />)}
                        <ul className="dropdown-menu">
                            {userType ? (
                                <div>
                                    <li className="dropdown-item" onClick={() => handleCreateChatClick()} >管理活动</li>
                                </div>
                            ) : (
                                <div />)}
                            {isMobile && <li className="dropdown-item"> <Link style={{ color: "white", textDecoration: "none" }} to={"/"} state={{ shouldScroll: true }} >未来活动</Link></li>}
                            {isMobile && <li className="dropdown-item"><a style={{ color: "white", textDecoration: "none" }} href="/ChatHistory">活动历史</a></li>}
                            <li className="dropdown-item" onClick={() => handleLogoutClick()} >登出</li>
                        </ul>
                    </div>
                ) : (
                    /* check button component for more info */
                    < Button onClick={handleLoginClick} scssChoice="motion-button">
                        登陆
                    </Button>)}
            </div>
        </nav >
    )
};

export default Header;