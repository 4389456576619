import { useEffect, useState } from "react";
import Button from "../../Components/Button";
import axios from "axios";
import StarsRating from "react-star-rate";

const FeedbackForm = ({ userId, chatId }) => {
    const [starValue, setStarValue] = useState(0);
    const [form, setForm] = useState({
        title: "",
        comment: "",
        user: "",
        chat: "",
        rate: 0.0,
    });

    const handleKeyDown = (event) => {
        //prevent enter key from submit form
        if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
            event.preventDefault();
        }
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        console.log(form);
    };

    const handleSubmit = async (e) => {
        // Prevents the default form submit action
        e.preventDefault();
        if (starValue === 0.0) {
            alert("别忘了打个分！");
            return
        }
        const readyForm = { ...form, user: userId, chat: chatId, rate: starValue };
        console.log('Form to be submitted:', readyForm);
        await axios.post(process.env.REACT_APP_BACKEND_DOMAIN + '/feedback/create-new-feedback', readyForm, { withCredentials: true })
            .then(response => {
                if (response.status(200)) {
                    alert("评论成功！");
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        console.log('Form submitted');
        window.location.reload();
    };

    useEffect(() => {

    }, []);

    const starStyles = {

        style: { position: "relative", top: "2vh", alignSelf: "flex-end" },

        full: {
            star: { fontSize: '30px' },
        },
        half: {
            star: { fontSize: '30px' },
        },
        zero: {
            star: { fontSize: '30px', color: 'lightgrey' },
        },
    };

    return (
        <div>
            <div className="feedback-form">
                <form onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
                    <label className="require-mark" htmlFor="title">标题:</label>
                    <input
                        style={{ maxWidth: "30vw", minHeight: "5vh" }}
                        type="text"
                        id="title"
                        name="title"
                        placeholder="请输入标题！"
                        maxLength="100"
                        value={form.title}
                        onChange={handleChange}
                        required
                    />

                    <label className="require-mark" htmlFor="title">评价:</label>
                    <textarea
                        style={{ minWidth: "90%", minHeight: "20vh" }}
                        id="comment"
                        name="comment"
                        placeholder="请输入标题！"
                        maxLength="100"
                        value={form.comment}
                        onChange={handleChange}
                        required
                    />
                    <StarsRating
                        value={starValue}
                        style={starStyles}
                        onChange={value => {
                            setStarValue(value);
                        }}
                    />
                    <Button id="leave-comment-button" type="submit" scssChoice="fill-button">
                        发送评论
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default FeedbackForm;