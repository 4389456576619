import { useEffect, useState } from "react";
import { getFeedbackByQuery } from "../../../apiServices";
import { Grid } from 'react-loading-icons';
import StarsRating from 'react-star-rate';
import "./feedbacks.scss";
import { useNavigate } from "react-router-dom";

const Feedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [isLoding, setIsLoding] = useState(true);

    //callback function to pause the scrolling animation of the feedback container
    const handlePausedChange = (newState) => {
        setIsPaused(newState);
    }

    const getFeedbacksData = async () => {
        try {
            const feedbacksDetail = await getFeedbackByQuery({ rate: "4", comparison: "gte" });
            setIsLoding(false);
            setFeedbacks(feedbacksDetail);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getFeedbacksData();
    }, []);

    return (
        <div className="feedbacks-container" >
            {isLoding ? (<Grid className="loading-adjust" stroke="white" speed={.75} />
            ) :
                (!feedbacks.length ? (<h3 style={{ alignSelf: "center", position: "relative", left: "40vw" }}>暂时还没有评论呢～</h3>) : (<div>
                    {/*using two identical feedbacks data to mock the scrolling effect  */}
                    <FeedbackContainer feedbacks={feedbacks} animation={"scroll-primary-feedbacks"} setPaused={handlePausedChange} pausedState={isPaused} />
                    <FeedbackContainer feedbacks={feedbacks} animation={"scroll-secondary-feedbacks"} setPaused={handlePausedChange} pausedState={isPaused} />
                </div>)
                )}
        </div>
    );
}

//good-feedbacks container for animation
const FeedbackContainer = ({ feedbacks, animation, setPaused, pausedState }) => {
    const navigate = useNavigate();

    const onFeedbaclClick = (feedback) => {
        navigate(`/ExpiredChat/${feedback.chat}`);

    };
    const starStyles = {

        style: { height: '25px', fontSize: '20px', alignSelf: 'flex-end' },

        full: {
            star: { fontSize: '20px' },
        },
        half: {
            star: { fontSize: '20px' },
        },
        zero: {
            star: { fontSize: '20px', color: 'lightgrey' },

        },
    };
    return (
        <div className={animation} >
            <div className={`scroll-container ${pausedState ? 'paused' : ""}`}>
                {feedbacks.map(feedback => (
                    <div key={feedback._id} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)} className="feedback" onClick={() => { onFeedbaclClick(feedback) }}>
                        <h3>{feedback.title}</h3>
                        <div>
                            <div> <img src={feedback.user.avatar} alt="头像不见了" /> &nbsp;{feedback.user.givenName}&nbsp;{feedback.user.familyName}&nbsp;</div>
                            <span style={{ color: 'grey' }}>{feedback.createdAt}</span>
                        </div>
                        <span>{feedback.comment}</span>
                        <StarsRating
                            value={feedback.rate}
                            disabled={true}
                            style={starStyles}
                        />
                    </div>
                ))
                }
            </div>
        </div >)
}

export default Feedbacks;

