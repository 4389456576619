import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./termsOfUse.scss"


const TermsOfUse = () => {

    return (
        <div>
            <div className="body-container share-background">
                <Header />
                <h1 style={{ display: "flex", textAlign: "center", justifyContent: "space-around", margin: "10vh 1em 0 1em" }}>使用条款</h1>
                <div id="use-terms" style={{ alignSelf: "center", maxWidth: "80vw", fontSize: "20px", letterSpacing: "3px", margin: "5vh 0" }} className="text-container">
                    <p>在使用本网站前，请仔细阅读以下使用条款。 本网站由PSYCKITCHEN (“网站”或“我们”). 发布并维护。下列使用条款和条件 (“本使用条款”) 适用于您访问和使用&nbsp;上或通过其提供的任何内容、功能和服务 (“网站”)。</p>

                    <p>访问或使用本网站即表示您已经接受本使用条款，并同意遵守本使用条款并受其约束。且您已满18周岁或已获得家长或监护人的同意，
                        同时您有完全民事能力接受本使用条款。如果您不希望遵守本使用条款，您不得访问或使用本网站。</p>

                    <p><h3 style={{ textAlign: "center" }}>网站访问与账户安全</h3><br />
                        我们保留不经通知即自行决定撤销或修订本网站以及我们在本网站上提供的任何服务或资料的权利，其中可能包括删除过期内容及改正技术错误或打印错误。
                        如果本网站在任何时间或在任何期间内因任何原因而全部或部分无法访问，我们将不承担任何责任。我们可不时限制对本网站的某些部分或整个网站的访问。</p>

                    <p>您负责为您访问本网站做出所有必要的安排，并负责确保通过您的互联网连接访问本网站的所有人士均知悉并遵守本使用条款。为访问本网站或本网站提供的某些资源，您可能会被要求提供某些注册信息或其他信息。您在本网站上提供的所有信息均正确、最新并完整是您使用本网站的条件之一。<br />
                        如果作为我们的安全程序的一部分，您选择或被提供了用户名、密码或任何其他信息，您必须将该等信息视为保密信息，且不得将该等信息披露给任何其他人士或实体。此外，您确认您的账户专属于您个人，并同意不向任何其他人士提供使用您的用户名、密码或其他安全信息访问本网站或其任何部分的权限。您同意，如您获知对您的用户名或密码的任何未经授权的访问或使用或任何其他安全漏洞，您将立即通知我们。您还同意确保在每次访问结束时退出您的账户。当您使用公用或共享计算机访问您的账户时，您应当特别注意，以确保其他人无法查看或记录您的密码或其他个人信息。</p>

                    <p>如果我们认为您违反了本使用条款的任何规定，我们有权随时禁用您选择的或我们提供的任何用户名、密码或其他识别符。</p>

                    <p><h3 style={{ textAlign: "center" }}>所有权</h3><br />
                        本网站及其全部内容、特性和功能 (包括但不限于所有信息、软件、文本、展示、图像、视频和音频以及前述各项的设计、选择和排列), 均属网站所有者，网站许可方及该等资料的其他提供方所有。</p>

                    <p><h3 style={{ textAlign: "center" }}>网站的使用</h3><br />
                        本使用条款允许您仅为个人非商业用途使用本网站。您不得复制、传播、修改、公开展示、公开演播、重新发布、下载、存储或传输本网站上的任何资料或利用其创造任何衍生作品，但下列情形除外：(a)您可以在访问及浏览上述资料时附带将其复本临时存储在随机存储器中；(b)您可以存储您的网页浏览器为提升显示性能之目的而自动缓存的文件；及(c)您可以为个人非商业用途打印或下载本网站数量合理的页面的一份复本，但不得将其用于进一步复制、发布或传播。您不得修改本网站任何资料的复本，在本网站资料的复本中删除或变更任何著作权、商标或其他专有权利通知，或者为任何商业目的访问或使用本网站的任何部分或通过本网站提供的任何服务或资料。</p>

                    <p>如果您违反本使用条款打印、复制、修改、下载或以其他方式使用或允许任何其他人士访问本网站的任何部分，则您使用本网站的权利将立即终止，并且您必须根据我们的选择返还或销毁您制作的任何资料复本。本网站或本网站任何内容的任何权利、权属或权益均未通过默示、禁止反言或其他方式转让给您，并且网站保留一切未明确授予您的权利。未经本使用条款明确允许，对于本网站的任何使用应视为违反本使用条款，并且可能违反著作权、商标及其他可适用的法律。</p>

                    <p><h3 style={{ textAlign: "center" }}>禁止使用</h3><br />
                        您仅可为合法目的并依据本使用条款使用本网站。作为您访问及使用本网站的明示条件之一，您同意不会：(a)以违反任何适用的联邦、州、地方或国际法律或法规的任何方式使用本网站；(b)利用本网站发送、故意接收、上传、下载、使用或重新使用不符合本使用条款规定的内容标准的任何资料；(c)利用本网站进行可能以任何方式过度负载、损坏、中断、损害本网站或使本网站不能正常运转或干扰本网站正常运转的任何行为，或者限制、阻止或影响任何其他用户使用或享有本网站的行为；或(d)以侵犯本网站其他用户隐私权的任何方式使用本网站。</p>

                    <p><h3 style={{ textAlign: "center" }}>商标</h3><br />
                        网站名称及所有相关名称、标识、产品与服务名称、图案和标语均为网站或其关联方或许可方的商标。未经网站事先书面许可，您不得使用该等标志。本网站上所有其他名称、标识、产品与服务名称、图案和标语均为相关所有人分别拥有的商标</p>

                    <p><h3 style={{ textAlign: "center" }}>用户生成内容</h3><br />
                        本网站可能会向您提供参与留言板、聊天室、论坛与其他互动功能（合称“互动服务”）的机会，从而使用户能够在本网站上或通过本网站发布、提交、公布、展示或向其他用户或其他人士传输（合称“发布”）内容或资料（合称“用户内容”）。</p>

                    <p>所有用户内容都必须遵守本使用条款中规定的内容标准。您在本网站上撰写或发布的任何内容都将被视为非保密且非专有的信息。在本网站上提供任何用户内容即表示您向我们、我们的关联方与服务提供方以及我们与前述各方各自的每一名被许可方、继承人和受让人授予使用、复制、修改、演播、展示、传播及以其他方式向第三方披露任何该等内容或资料的权利。</p>



                    <p>您陈述并保证：(a)您拥有或控制用户内容的全部权利，并有权授予在上文授予的许可；及(b)您的所有用户内容都将遵守本使用条款。</p>

                    <p>您理解并确认，您对您发布的任何用户内容都负有责任，并且您而非网站所有者对该等内容负完全责任，包括但不限于该等内容的合法性、可靠性、准确性和适当性。</p>



                    <p>我们有权：(a)以任何理由或无理由自行决定删除或拒绝发布任何用户内容；(b)针对任何用户内容采取我们依据自己的裁量认为必要或适当的任何行动，包括在我们认为该等用户内容违反了本使用条款（包括内容标准）、侵犯了任何人士或实体的任何知识产权或其他权利、威胁了本网站用户或公众的人身安全或可能导致网站承担责任的情况下采取行动；(c)向声称您发布的资料侵犯其权利（包括知识产权或隐私权）的任何第三方披露您的身份或其他相关信息；(d)就本网站的任何非法使用或未经授权的使用采取适当的法律行动，包括但不限于转交执法机关处理；或(e)因您违反本使用条款的任何规定终止或中止您访问全部或部分网站的权限。</p>



                    <p>在不限制前述规定的前提下，当任何执法机关或法院要求或指示我们披露在本网站上或通过本网站发布任何内容或资料的任何人士的身份或其他信息时，我们有权全力配合该等指令。您放弃因网站及其关联方、被许可方与服务提供方中的任何一方在其调查期间或由于其调查采取的任何行动所产生的，以及因前述各方或执法机关进行的调查致使采取的任何行动所产生的任何权利主张，并使前述各方免受该等权利主张的损害。</p>

                    <p>在相关资料公布于本网站前，我们不承诺就该等资料进行审查，并且无法保证异议资料公布后即时删除该等资料。因此，在法律允许的范围内，对于我们就任何用户或第三方提供的信息传输、通信或内容所采取的任何作为或不作为，我们不承担任何责任。无论是否进行本节所述之活动，我们均不对任何人承担责任或义务。</p>



                    <p><h3 style={{ textAlign: "center" }}>内容标准</h3><br />
                        本标准适用于任何及所有用户内容及互动服务的使用。用户内容必须全面遵守所有适用的联邦、州、地方与国际法律法规(包括欧洲法律)。用户内容不得：(a)包含有害的、胁迫的、诽谤的、侮辱性的、骚扰的、污蔑的、淫秽的、露骨的、亵渎的或在其他方面令人反感的资料；(b)宣扬暴力或种族、性别、宗教、国籍、残疾、性取向或年龄歧视；(c)侵犯任何其他人士或实体的任何专利、商标、商业秘密、著作权或其他知识产权；(d)侵犯他人的合法权利（包括形象权和隐私权），或者包含可能产生任何适用法律或法规项下的任何民事或刑事责任的任何资料；或(e)涉及商业活动或销售，如比赛、抽奖及其他有奖促销、实物交易或广告。</p>



                    <p><h3 style={{ textAlign: "center" }}>著作权侵权</h3><br />
                        我们高度重视著作权侵权的权利主张，并将就遵守适用法律的关于涉嫌侵犯著作权的通知作出回应。如果您认为可在本网站上访问的任何用户内容或其他资料侵犯了您的著作权，您可以向我们提交书面通知（发送电子邮件至psyckitchenservice@gmail.com），要求删除该等用户内容或其他资料（或停止对该等用户内容或其他资料的访问）。您提交的书面通知需包含下列信息：</p>

                    <ul>
                        <li >
                            <p>您的亲笔签名或电子签名</p>
                        </li>
                        <li >
                            <p>指明您认为已被侵权的受著作权保护的作品，或者如果您的权利主张涉及网站上的多件作品，则提供该等作品的代表性清单</p>
                        </li>
                        <li >
                            <p>我们可以与您联系的充分信息（包括您的姓名、通讯地址、电话号码及（如可提供）电子邮件地址）</p>
                        </li>
                        <li >
                            <p>关于您善意认为相关受著作权保护的资料的使用未经著作权所有人或其代理或法律授权的声明</p>
                        </li>
                        <li >
                            <p>关于您提交的书面通知所含的信息准确的声明；及</p>
                        </li>
                        <li >
                            <p>在作伪证便受处罚的前提下，关于您被授权代表著作权所有人行事的声明。</p>
                        </li>
                    </ul>

                    <p><h3 style={{ textAlign: "center" }}>用户提交的内容</h3><br />
                        您向我们提交的任何信息、想法、意见、建议或其他反馈（合称“提交的内容”）应成为我们专有的财产，并应被视为非保密且非专有的信息。我们有权在不通知您或向您支付报酬的情况下，随时以任何媒介使用、复制、修改、演播、展示、许可、公布、传播以及其他方式向第三方披露任何提交的内容。但是，我们无义务使用该等提交的内容，并且您在此放弃强制该等使用的任何权利。</p>

                    <p><h3 style={{ textAlign: "center" }}>建立与网站的链接</h3><br />
                        您可以建立与我们主页的链接，但前提是，建立链接的方式公平、合法并且不会损害或利用我们的声誉。但是，您不得以暗示我们与您存在任何形式的关联或对您作出任何形式的批准或认可的方式建立链接。受限于上述规定，您不得：(a)在并非您拥有的任何网站上建立连接；(b)促使本网站或其任何部分通过加框链接、深度链接或内链等方式在任何其他网站上显示或看起来似乎在任何其他网站上显示；(c)链接至本网站除主页以外的任何部分；或(d)以不符合本使用条款的任何其他规定的其他方式就本网站的资料采取任何行动。</p>
                    <p>您同意配合我们促使任何未经授权的加框链接或链接立即停用。我们保留不经通知即取消链接许可的权利。我们可随时不经通知即自行决定禁用任何或所有链接。</p>

                    <p><h3 style={{ textAlign: "center" }}>网站包含的链接</h3><br />
                        如果本网站包含第三方提供的其他网站与资源的链接，则上述链接仅为您的便利而设。上述链接包括广告中包含的链接，包括横幅广告与赞助商链接。我们无法控制上述网站或资源的内容，并且不对该等网站与资源或者可能因您使用该等网站与资源产生的任何损失或损害承担任何责任。如果您决定访问链接至本网站的任何第三方网站，则您在访问时完全自行承担风险，并应受该等网站的使用条款和条件的约束。</p>
                    <p><h3 style={{ textAlign: "center" }}>免责声明</h3><br />
                        在法律允许的最大限度内，本网站、本网站的内容以及通过本网站获取的任何服务或项目均“按现状”并“按现有条件”提供，网站未就其作出任何保证。在适用法律允许的最大范围内，我们明确否认所有明示或默示的保证，包括但不限于关于适销性、适合特定用途及无侵权的保证。我们未就本网站或通过本网站获取的内容或服务的完整性、安全性、准确性、可靠性、质量、可获取性或运行作出任何陈述或保证。我们不保证本网站将持续无误地运营，或者本网站的缺陷将得以改正，或者本网站或提供本网站的服务器不含病毒或其他有害组件，或者本网站将协同任何其他产品或软件运转或运营，或者本网站将在其他方面满足您的需求或预期。</p>
                    <p><h3 style={{ textAlign: "center" }}>责任限制</h3></p>

                    <p>在法律允许的最大限度内，网站所有者、网站的关联方或者前述各方的许可方、服务提供方、员工、代理、管理人员在任何情况下都不会对根据任何法律理论因您使用或无法使用本网站、链接至本网站的任何网站、本网站或该等其他网站上的任何内容或通过本网站或该等其他网站获取的任何服务或项目产生的或与之相关的任何种类的损害（包括任何直接的、间接的、特殊的、附带的、衍生的或惩戒的损害）承担责任，包括但不限于收入损失、利润损失、业务或预期结余损失、使用损失、商誉损失和数据损失，无论是否由侵权（包括过失）、违约或其他原因引起，并且即便该等损害可以预见。</p>
                    <p><h3 style={{ textAlign: "center" }}>用户责任</h3><br />
                        在可适用的法律允许的限度内，对于因您违反本使用条款、使用本网站（包括但不限于您的用户内容）、除本使用条款明确授权外使用本网站的任何内容、服务和产品或使用从本网站获取的任何信息引起的或与之相关的任何权利主张、责任、损害、判决、裁决、损失、成本、费用或支出（包括合理的律师费），您同意赔偿网站所有人，为之抗辩并使其免受损害。</p>
                    <br />
                    <br />
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default TermsOfUse;